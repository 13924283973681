<template>
  <div id="numbersbox">
    <div id="prs">
      <p class="title">Call Now</p>

      <p class="number">{{ prs }}</p>

      <p class="terms">£2 PER MIN + YOUR NETWORK ACCESS CHARGE</p>
    </div>

    <div id="mobile">
      <p class="title">Mobiles</p>

      <p class="number">{{ vsc }}</p>

      <p class="terms">£2 PER MIN + £2 CONN. SMS</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    prs: String,
    vsc: String
  },
}
</script>

<style scoped>
p {
  margin: 0px;
  padding: 0px;
}

#numbersbox {
  width: 552px;
  height: 64px;
  background-image: url('~@/assets/numbers-bg.jpg');
  background-color: #fff;
  float: left;
}

#prs {
  float: left;
  padding-left: 30px;
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

#mobile {
  float: right;
  padding-right: 20px;
}

.title {
  font-family: 'GothamPro-Bold';
  color: #f233a5;
  padding-top: 4px;
}

.number {
  font-family: 'GothamPro-Bold';
  font-size: 30px;
  margin-left: -2px;
  margin-top: -12px;
}

.terms {
  font-family: 'GothamPro-Bold';
  font-size: 10px;
  text-transform: uppercase;
  margin-top: -12px;
}
</style>
