<template>
  <div id="urlbox">
    <p id="cta">
      <span>Join In + Interact For Free</span>
    </p>
    <p id="url">BABESTATION.UK</p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
p {
  margin: 0px;
  padding: 0px;
}

#urlbox {
  background: #163d86 url('~@/assets/background-blue.png');
  width: 246px;
  height: 65px;
  float: left;
}

#cta {
  font-family: 'GothamPro-Bold';
  font-size: 12px;
  color: #fefefe;
  padding-left: 12px;
  text-transform: uppercase;
  padding-top: 14px;
  margin: 0px;
}

#cta span {
  color: #d8d8d8;
}

#url {
  font-family: 'GothamPro-Bold';
  font-size: 20px;
  color: #fefefe;
  padding-left: 12px;
  padding-top: -8px;
  margin: 0px;
}
</style>
