<template>
  <div class="emergency">
    <div style="height: 453px;"></div>
    <LowerThird :channel=channel :platform=platform />
  </div>
</template>

<script>
import LowerThird from '@/components/LowerThird/LowerThird.vue'

export default {
  name: "Emergency",
  props: {
    channel: String,
    platform: String
  },
  components: {
    LowerThird
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .emergency {
    width: 1024px;
    height: 576px;
    overflow: hidden;
  }
</style>
