<template>
  <div id="lowerthird">
    <div id="content">
      <LogoBox />
      <NumbersBox :prs=prs :vsc=vsc />
      <URLBox />
    </div>
    <div id="tcs">
      <marquee-text :duration="180"
        >{{ terms }}</marquee-text
      >
    </div>
  </div>
</template>

<script>
import LogoBox from '@/components/LowerThird/LogoBox.vue'
import NumbersBox from '@/components/LowerThird/NumbersBox.vue'
import URLBox from '@/components/LowerThird/URLBox.vue'
import MarqueeText from 'vue-marquee-text-component'

import emergency from '@/assets/emergency.json'

export default {
  name: 'LowerThird',
  props: {
    channel: String,
    platform: String
  },
  data(){
    return{
      emergencyJson: emergency,
      prs: "",
      vsc: "",
      terms: ""
    }
  },
  mounted() {
    this.prs = this.emergencyJson[this.platform][this.channel]["prs"];
    this.vsc = this.emergencyJson[this.platform][this.channel]["vsc"];
    this.terms = this.emergencyJson[this.platform][this.channel]["terms"];
  },
  components: {
    LogoBox,
    NumbersBox,
    URLBox,
    MarqueeText
  }
}
</script>

<style scoped>
#lowerthird {
  width: 1024px;
  height: 122px;
  bottom: 0px;
  background-color: #000;
}

#content {
  width: 1024px;
  height: 65px;
}

#tcs {
  clear: both;
  color: #d8d8d8;
  padding: 4px;
  font-family: 'GothamPro-Bold';
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
