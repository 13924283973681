<template>
  <div id="logobox">
    <img id="logo" src="~@/assets/logo.png" />
  </div>
</template>

<script>
//import gsap from 'gsap'

export default {
  mounted: function() {
    //gsap.to('#logo', { duration: 3, scale: 1.1 })
    //gsap.from('#logo', { duration: 1, scale: 1.1 })
  }
}
</script>

<style scoped>
#logobox {
  background-image: url('~@/assets/logo-bg.jpg');
  background-color: #e00e7a;
  width: 226px;
  height: 64px;
  float: left;
}

#logo {
  width: 148px;
  height: 26px;
  float: right;
  margin-top: 22px;
  margin-right: 10px;
}
</style>
